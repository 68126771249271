@import '../constants/colors';

.auth-title {
  font-size: 3.5em;
  font-weight: 700;
  line-height: auto;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.015em;
  color: $text-color-111010;
}

.secondary-title {
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 0.006rem;
  line-height: auto;
  font-family: Roboto, sans-serif;
  color: $text-color-secondary;
}

.h2-primary {
  font-size: 1.75rem;
  line-height: 2.625rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
  font-family: Poppins, sans-serif;
  color: $text-color-h2;
}

.p-description {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.016rem;
  font-family: Roboto, sans-serif;
  color: $text-color-description;
}

.checked-text {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: $checked-text-color;
  font-family: Poppins, sans-serif;
}

.correct-title {
  color: $form-title-color;
}

.h3-primary {
  font-size: 1.25rem;
  letter-spacing: 0.016rem;
  color: $text-color-h2;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.h4-primary {
  font-size: 1rem;
  letter-spacing: 0.016rem;
  color: $text-color-h2;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.h5-primary-inter {
  font-size: 0.875rem;
  color: $text-color-h5-primary;
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.h5-primary-poppins {
  font-size: 0.875rem;
  color: $text-color-regular;
  font-family: Poppins, sans-serif;
  font-weight: 475;
  letter-spacing: 0.00625rem;
}

.error-title {
  color: $error-color;
}

.price-text {
  font-size: 3.44rem;
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  color: $price-text-color;
}

.roboto-regular-1_125rem {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.00625rem;
  color: $text-color-description;
}

.roboto-regular-1rem {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.00625rem;
  color: $text-color-description;
}

.roboto-regular {
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: $text-color-description;
}

.poppins-regular {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  letter-spacing: 0.006rem;
  color: $text-color-regular;
}

.poppins-medium {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  letter-spacing: 0.006rem;
  color: $text-color-regular;
}

.inter-semi-bold {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.003rem;
  color: $inter-font-text-color;
}

.inter-medium {
  font-family: Inter, sans-serif;
  font-weight: 475;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.003rem;
  color: $inter-font-medium-text-color;
}

.text-description {
  color: $dynamic-header-text-color;
  font-size: 0.875rem;
}

.dynamic-header-text {
  color: $dynamic-header-text-color;
  font-size: 0.922rem;
  line-height: 1.5rem;
}

.dynamic-header-active-text {
  color: $dynamic-header-active-text-color;
  font-size: 0.922rem;
  line-height: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.logo-title {
  font-size: 1.25rem;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  color: $text-color-main;
}

.submenu-title {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  color: $text-color-secondary;
}

.sidebar-item-title {
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  font-weight: 475;
  color: $text-color-regular;
  white-space: nowrap;
}

.header-name-title {
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.00625rem;
  font-weight: 600;
  color: $text-color-regular;
}

.header-job-title {
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.00625rem;
  font-weight: 400;
  color: $text-color-secondary;
}

.sort-title {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  font-weight: 400;
  color: $text-color-description;
}

.sort-dropdown-item {
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  font-weight: 500;
  color: $text-color-main;
}

//Fonts

.f-roboto-primary {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: $text-color-main;
  letter-spacing: 0.00625rem;
}

.f-roboto-m-primary {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: $text-color-main;
  letter-spacing: 0.00625rem;
}

.f-roboto-secondary {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: $text-color-secondary;
  letter-spacing: 0.00625rem;
}

.f-roboto-social {
  font-family: Roboto, sans-serif;
  font-size: 0.937rem;
  font-weight: 400;
  color: $social-media-text-color;
}

.f-poppins-bold {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  color: $text-color-main;
}

.f-poppins-bold-white {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  color: $text-color-white;
}

.f-poppins-secondary {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  color: $text-color-secondary;
  letter-spacing: 0.0125rem;
}

.f-inter-secondary {
  font-family: Inter, sans-serif;
  font-weight: 475;
  color: $text-color-leads-filter-button;
}

.text-header-title-h2 {
  color: $text-header-title-h2;
  font-family: Inter, sans-serif;
}

.text-inter-regular {
  color: $text-inter-color;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.312rem;
}

.text-inter-bold-h3 {
  color: $text-inter-bold-h3-color;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.0163rem;
}

.text-inter-regular-secondary {
  color: $text-inter-regular-secondary;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.312rem;
}

.text-inter-regular-description {
  color: $text-inter-regular-description;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.312rem;
}

.text-message-inter-regular {
  color: $text-message-inter-regular;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.text-inter-regular-1rem {
  color: $text-inter-regular-1rem;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-inter-medium {
  color: $text-inter-color;
  font-family: Inter, sans-serif;
  font-weight: 475;
  font-size: 0.875rem;
  line-height: 1.312rem;
}

.text-inter-approved {
  color: $text-color-approved;
  font-family: Inter, sans-serif;
  font-weight: 475;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.text-inter-pending {
  color: $text-color-pending;
  font-family: Inter, sans-serif;
  font-weight: 475;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.text-template-description {
  font-family: Inter, sans-serif;
  color: $text-template-description-color;
  font-size: 0.812rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.text-inter-bold-1rem {
  color: $text-inter-regular-1rem;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.abril-fatface-regular {
  color: $text-abril-fatface-color;
  font-family:
    Abril Fatface,
    sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.5rem;
}

.nunito-semibold-white {
  font-family: Nunito, sans-serif;
  color: $text-color-white;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.011rem;
}

.nunito-bold {
  font-family: Nunito, sans-serif;
  color: $text-nunito-bold;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: -0.011rem;
}

.roboto-medium-soft-silver {
  font-family: Roboto, sans-serif;
  color: $text-color-soft-silver;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
}

.text-header-description {
  color: $text-header-description;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.text-input-placeholder {
  color: $text-header-description;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.312rem;
}

.text-campaign-action-button-description {
  color: $text-header-description;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.text-campaign-action-button-title {
  color: $text-header-description;
  font-family: Inter, sans-serif;
  font-weight: 475;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: $text-campaign-action-button-title;
}

.text-hover-primary {
  &:hover {
    span {
      color: $text-hover-primary;
      font-weight: 475;
    }
    cursor: pointer;
  }
}

.text-hover-danger {
  &:hover {
    span {
      color: $text-color-e73f3f;
      font-weight: 475;
    }
    cursor: pointer;
  }
}

.active-sidebar-font {
  color: $active-sidebar-item-color;
}

.button-text-primary {
  font-family: Inter, sans-serif;
  color: $text-color-white;
  font-weight: 475;
  line-height: 1.32rem;
  font-size: 0.875rem;
}

.hovered-sidebar-font {
  &:hover {
    cursor: pointer;
    span {
      color: $active-sidebar-item-color;
    }
  }
}

//font sizes
.fs-0_5rem {
  font-size: 0.5rem;
}

.fs-0_6875rem {
  font-size: 0.6875rem;
}

.fs-0_75rem {
  font-size: 0.75rem;
}

.fs-0_875rem {
  font-size: 0.875rem;
}

.fs-1rem {
  font-size: 1rem;
}

.fs-1_125rem {
  font-size: 1.125rem;
}

.fs-1_25rem {
  font-size: 1.25rem;
}

.fs-1_5rem {
  font-size: 1.5rem;
}

.fs-1_75rem {
  font-size: 1.75rem;
}

.fs-2rem {
  font-size: 2rem;
}

.fs-2_5rem {
  font-size: 2.5rem;
}

.fs-2_625rem {
  font-size: 2.625rem;
}

.fs-4_375rem {
  font-size: 4.375rem;
}

//line-height
.lh-1rem {
  line-height: 1rem;
}

.lh-1_125rem {
  line-height: 1.125rem;
}

.lh-1_375rem {
  line-height: 1.375rem;
}

.lh-1_5rem {
  line-height: 1.5rem;
}

.lh-2rem {
  line-height: 2rem;
}

.lh-2_125rem {
  line-height: 2.125rem;
}

.lh-2_25rem {
  line-height: 2.25rem;
}

.lh-2_5rem {
  line-height: 2.5rem;
}

.lh-2_625rem {
  line-height: 2.625rem;
}

.lh-5_25rem {
  line-height: 5.25rem;
}

//text-align
.text-center {
  text-align: center;
}

//font-weight

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

//color
.color-white {
  color: $text-color-white;
}

.c-secondary {
  color: $text-color-secondary;
}

.c-main {
  color: $text-color-main;
}

.c-regular {
  color: $text-color-regular;
}

.c-title {
  color: $text-color-title;
}

.c-clicks {
  color: $text-color-clicks;
}

.c-black {
  color: $text-color-black;
}

.c-454545 {
  color: $text-color-454545;
}

.c-52a86e {
  color: $text-color-52a86e;
}

.c-5f5f61 {
  color: $text-color-5f5f61;
}

.c-262628 {
  color: $text-color-262628;
}

.c-impressions {
  color: $text-color-impressions;
}

.c-warm-leads {
  color: $text-color-warm-leads;
}

.c-conversations {
  color: $text-color-conversations;
}

.c-replies {
  color: $text-color-replies;
}

.c-e73f3f {
  color: $text-color-e73f3f;
}

.c-0062ff {
  color: $text-color-0062ff;
}

.c-696974 {
  color: $text-color-696974;
}

.c-1751d0 {
  color: $text-color-1751d0;
}

.c-50b5ff {
  color: $text-color-50b5ff;
}

.c-111928 {
  color: $text-color-111928;
}

.c-6b7280 {
  color: $text-color-6b7280;
}

.c-1e429f {
  color: $text-color-1e429f;
}

.c-0e9f6e {
  color: $text-color-0e9f6e;
}

.c-9ca3af {
  color: $text-color-9ca3af;
}

.c-ffc542 {
  color: $text-color-ffc542;
}

.c-888e9e {
  color: $text-color-888e9e;
}

.c-555f75 {
  color: $text-color-555f75;
}

.c-0f0f10 {
  color: $text-color-0f0f10;
}

.c-323741 {
  color: $text-color-323741;
}

.c-171725 {
  color: $text-color-171725;
}

.c-fafafb {
  color: $text-color-fafafb;
}

.c-8c8c8c {
  color: $text-color-8c8c8c;
}

.c-8c4df3 {
  color: $text-color-8c4df3;
}

.c-f34d4d {
  color: $text-color-f34d4d;
}

.c-4dd5f3 {
  color: $text-color-4dd5f3;
}

.c-47ac34 {
  color: $text-color-47ac34;
}

//font family
.ff-inter {
  font-family: Inter, sans-serif;
}

.ff-poppins {
  font-family: Poppins, sans-serif;
}

.ff-montserrat {
  font-family: Montserrat, sans-serif;
}

.ff-roboto {
  font-family: Roboto, sans-serif;
}

.ff-archivo {
  font-family: Archivo, sans-serif;
}

//letter spacing
.ls-0_00625rem {
  letter-spacing: 0.00625rem;
}

.ls-0_01rem {
  letter-spacing: 0.01rem;
}

.ls-0_05rem {
  letter-spacing: 0.05rem;
}

.ls-_-0_0016rem {
  letter-spacing: -0.0016rem;
}

.ls-_-0_002rem {
  letter-spacing: -0.002rem;
}

.ls-_-0_0026rem {
  letter-spacing: -0.0026rem;
}

.td-through {
  text-decoration: line-through;
}

.td-underline {
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.error-message {
  color: $error-color;
  font-size: 0.75rem;
  position: absolute;
  bottom: -0.9rem;
  letter-spacing: 0.006rem;
}
