@import '../constants/colors';

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border: none;
  border-radius: 0.625rem;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-primary {
  background-color: $button-color-primary;
  color: $button-text-color-primary;
  &:disabled {
    background-color: $button-color-primary-disabled;
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
}

.btn-secondary {
  background-color: $button-color-secondary;
  color: $button-text-color-secondary;
}

.btn-light-primary {
  background-color: $button-color-light-primary;
  color: $button-text-color-primary;
  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    opacity: 0.8;
  }
}

.btn-remove {
  background-color: $background-color-ff3b30;
  color: $button-text-color-primary;
  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    opacity: 0.8;
  }
}

.btn-header-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.375rem;
  width: 9.687rem;
  border: none;
  border-radius: 0.625rem;
  background-color: $button-color-primary;
  color: $button-text-color-primary;

  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  span {
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.00625rem;
    font-weight: 600;
    color: $button-text-color-primary;
  }
}

.btn-import-leads {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.375rem;
  width: 9.06rem;
  border: none;
  border-radius: 0.75rem;
  background-color: $button-color-primary;
  color: $button-text-color-primary;

  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  span {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.00625rem;
    font-weight: 600;
    color: $button-text-color-primary;
  }
}

.btn-header-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.375rem;
  width: 8.25rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  border: 1px solid $border-color-secondary;
  background-color: $bg-white;
  border-radius: 0.625rem;
  color: $button-text-color-secondary;

  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-leads-filter-status {
  width: 8.875rem;
  height: 2.375rem;
  background-color: $bg-white;
  border: 1px solid $border-color-leads-filter-button;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  font-family: Inter, sans-serif;
  font-weight: 475;
  font-size: 0.875rem;
  color: $text-color-leads-filter-button;
  &.active {
    background-color: $button-leads-filter-active;
    border: none;
    color: $text-color-white;
    &:hover {
      opacity: 0.8;
    }
  }
  &:hover {
    background-color: $button-leads-filter-active;
    border: none;
    color: $text-color-white;
    cursor: pointer;
  }
}

.btn-leads-filter-labels {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  background-color: $background-leads-filter-checkpoint;
  border: none;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  font-family: Inter, sans-serif;
  font-weight: 475;
  font-size: 0.75rem;
  color: $text-color-h2;
  &.active {
    background-color: $button-leads-filter-active;
    border: none;
    color: $text-color-white;
    &:hover {
      opacity: 0.8;
    }
  }
  &:hover {
    background-color: $button-leads-filter-active;
    border: none;
    color: $text-color-white;
    cursor: pointer;
  }
}

.btn-leads-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.31rem;
  width: 3.94rem;
  border: none;
  border-radius: 0.5rem;
  font-family: Inter, sans-serif;
  font-weight: 475;
  font-size: 0.875rem;
  color: $text-color-white;
  background-color: $button-leads-filter-active;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-component-header-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $button-component-header-primary-color;
  border: 1px solid $button-component-header-primary-border-color;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 475;
  font-family: Inter, sans-serif;
  color: $text-color-white;
  line-height: 1.125rem;
  padding: 0.4375rem 0.6875rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: auto;
    }
  }
}

.btn-component-header-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-white;
  border: 1px solid $button-component-header-secondary-border-color;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 475;
  font-family: Inter, sans-serif;
  color: $text-header-secondary-button-color;
  line-height: 1.125rem;
  padding: 0.4375rem 0.6875rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: auto;
    }
  }
}

.start-campaign-action-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: $background-campaign-action-buttons;
  border: 1px solid $button-component-header-secondary-border-color;
  border-radius: 0.5rem;
  height: 5.5rem;
  padding: 0 1rem;
}

.leads-real-time-sync-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: $button-component-header-primary-border-color;
  border-radius: 0.5rem;
  height: 2.625rem;
  padding: 0 3.125rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-convert-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-convert-secondary;
  border: 1px solid $button-component-header-secondary-border-color;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 475;
  font-family: Inter, sans-serif;
  color: $text-inter-color;
  line-height: 1.125rem;
  min-width: 7.75rem;
  height: 3.25rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-convert-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $button-color-primary;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: Inter, sans-serif;
  color: $text-color-white;
  line-height: 1.125rem;
  min-width: 8.625rem;
  height: 3.25rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-text-preview-primary {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background-color: $button-text-preview-primary;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: Inter, sans-serif;
  color: $text-color-white;
  padding: 0 0.875rem;
  line-height: 1.125rem;
  width: 100%;
  min-width: 10rem;
  height: 2.875rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-template-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $button-text-preview-primary;
  border-radius: 0.5rem;
  width: 6.5rem;
  height: 3rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-add-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $button-component-header-primary-color;
  border-radius: 0.5rem;
  width: 7.625rem;
  height: 2.375rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-add-image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $border-color-main;
  background-color: $bg-white;
  border-radius: 0.625rem;
  width: 9.25rem;
  height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: Inter, sans-serif;
  color: $text-inter-regular-1rem;
  line-height: 1.125rem;
  padding-left: 0.75rem;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-roboto-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: $button-roboto-primary-color;
  border-radius: 0.5rem;
  height: 2.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  color: $text-color-soft-silver;
  line-height: 1.125rem;
  padding: 0 1.25rem;
  letter-spacing: 0.00625rem;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-add-dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border-color-main;
  border-radius: 0.5rem;
  height: 1.81rem;
  width: 1.81rem;
  font-size: 1rem;
  font-weight: 500;
  color: $text-color-black;
  background-color: $bg-white;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-conversation-filter-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.625rem;
  height: 2.375rem;
  width: calc(45% - 0.5rem);
  font-family: Poppins, sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  color: $text-color-696974;
  background-color: $background-color-f1f1f5;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-conversation-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.625rem;
  height: 2.375rem;
  width: calc(55% - 0.5rem);
  font-family: Poppins, sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  color: $text-color-fafafb;
  background-color: $background-color-50b5ff;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-conversation-chart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  border-radius: 0.4rem;
  height: 1.625rem;
  width: 5.5rem;
  padding-left: 0.75rem;
  background-color: $bg-white;
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0125rem;
  color: $button-text-color-secondary;

  &.active {
    background-color: $button-color-primary;
    color: $text-color-white;
    &:hover {
      opacity: 0.8;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-highlights-share {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 1.25rem;
  height: 2.5rem;
  width: 6.5rem;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: $text-color-white;
  background-color: $background-color-181b1f;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-highlights {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: none;
  border-radius: 1.5rem;
  height: 3rem;
  width: 18rem;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: $text-color-fcfdfe;
  background-color: $background-color-0062ff;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-add-agent-bio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.375rem;
  width: 50%;
  min-width: 10rem;
  border: 1px solid $border-color-secondary;
  background-color: $bg-white;
  border-radius: 0.5rem;

  span {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: $text-color-111928;
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-agent-bio-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.375rem;
  border: 1px solid $border-color-secondary;
  background-color: $bg-white;
  border-radius: 0.5rem;

  span {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: $text-color-111928;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-agent-bio-save {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.31rem;
  width: 4.06rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $button-color-primary;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 475;
  color: $text-color-white;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
      cursor: auto;
    }
  }
}

.btn-disconnect {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.31rem;
  width: 4.06rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $background-color-ff0044;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 475;
  color: $text-color-white;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
      cursor: auto;
    }
  }
}

.btn-subscriptions-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.56rem;
  border: none;
  border-radius: 0.5rem;
  background-color: $button-roboto-primary-color;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: $text-color-white;

  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  span {
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    color: $text-color-white;
  }
}

.btn-subscriptions-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.56rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  border: 1px solid $border-color-secondary;
  background-color: $bg-white;
  border-radius: 0.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: $text-color-111928;
  position: relative;

  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .add-payment-method-error-message {
    position: absolute;
    bottom: -1rem;
    left: 0;
    font-size: 0.75rem;
    font-weight: 500;
    color: #fc5a5a;
  }
}

.btn-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.5rem;
  font-size: 0.875rem;
  letter-spacing: 0.00625rem;
  border: 1px solid $border-color-secondary;
  background-color: $bg-white;
  border-radius: 0.625rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: $text-color-888e9e;
  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.add-tag-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid transparent;
  height: 1.625rem;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  background-color: transparent;
  &:hover {
    cursor: pointer;
    border: 1px solid $border-color-f8fafb;
    background-color: $background-white;
    box-shadow: -1px 2px 4px 0px #eaedf2;
  }
}

.tag-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  border: none;
  height: 1.625rem;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  background-color: $background-color-f3f4f6;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.btn-generate-report {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 2.375rem;
  width: 11rem;
  border: none;
  border-radius: 0.625rem;
  background-color: $button-color-1e75ff;

  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.select-subscription-plan-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 0.25rem);
  height: 3.22rem;
  border: 1px solid #171725;
  background-color: #ffffff;
  border-radius: 0.625rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  color: #171725;
  cursor: pointer;

  &.active {
    border: 1px solid #50b5ff;
    background-color: #50b5ff;
    color: #fafafb;
  }
}
