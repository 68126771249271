.size-max {
  width: 100vw;
  height: 100vh;
}

//width

.w-auto {
  width: auto;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-21 {
  width: 21%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-32 {
  width: 32%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-48 {
  width: 48%;
}

.w-50 {
  width: 50%;
}

.w-52 {
  width: 52%;
}

.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-0_5rem {
  width: 0.5rem;
}

.w-0_625rem {
  width: 0.625rem;
}

.w-0_75rem {
  width: 0.75rem;
}

.w-1rem {
  width: 1rem;
}

.w-1_125rem {
  width: 1.125rem;
}

.w-1_25rem {
  width: 1.25rem;
}

.w-1_4rem {
  width: 1.4rem;
}

.w-1_5rem {
  width: 1.5rem;
}

.w-1_75rem {
  width: 1.75rem;
}

.w-1-875rem {
  width: 1.875rem;
}

.w-2rem {
  width: 2rem;
}

.w-2_25rem {
  width: 2.25rem;
}

.w-2_375rem {
  width: 2.375rem;
}

.w-2_75rem {
  width: 2.75rem;
}

.w-3_125rem {
  width: 3.125rem;
}

.w-3rem {
  width: 3rem;
}

.w-3_81rem {
  width: 3.81rem;
}

.w-4rem {
  width: 4rem;
}

.w-5rem {
  width: 5rem;
}

.w-5_875rem {
  width: 5.875rem;
}

.w-6_56rem {
  width: 6.56rem;
}

.w-7rem {
  width: 7rem;
}

.w-7_25rem {
  width: 7.25rem;
}

.w-7_5rem {
  width: 7.5rem;
}

.w-8rem {
  width: 8rem;
}

.w-8_5rem {
  width: 8.5rem;
}

.w-8_625rem {
  width: 8.625rem;
}

.w-8_875rem {
  width: 8.875rem;
}

.w-9rem {
  width: 9rem;
}

.w-9_375rem {
  width: 9.375rem;
}

.w-10rem {
  width: 10rem;
}

.w-11rem {
  width: 11rem;
}

.w-11_25rem {
  width: 11.25rem;
}

.w-12rem {
  width: 12rem;
}

.w-12_5rem {
  width: 12.5rem;
}

.w-13rem {
  width: 13rem;
}

.w-14rem {
  width: 14rem;
}

.w-15rem {
  width: 15rem;
}

.w-15_625rem {
  width: 15.625rem;
}

.w-16rem {
  width: 16rem;
}

.w-17rem {
  width: 17rem;
}

.w-20rem {
  width: 20rem;
}

.w-21_25rem {
  width: 21.25rem;
}

.w-24_2rem {
  width: 24.2rem;
}

.w-27_5rem {
  width: 27.5rem;
}

.w-30rem {
  width: 30rem;
}

.w-31_25rem {
  width: 31.25rem;
}

.w-35rem {
  width: 35rem;
}

.w-36_5rem {
  width: 36.5rem;
}

.w-39rem {
  width: 39rem;
}

.w-44rem {
  width: 44rem;
}

.w-50rem {
  width: 50rem;
}

//Minimal width
.mw-1_125rem {
  min-width: 1.125rem;
}

.mw-9rem {
  min-width: 9rem;
}

.mw-12rem {
  min-width: 12rem;
}

.mw-15rem {
  min-width: 15rem;
}

.mw-16rem {
  min-width: 16rem;
}

.mw-19rem {
  min-width: 19rem;
}

.mw-20rem {
  min-width: 20rem;
}

.mw-32rem {
  min-width: 32rem;
}

//Maximal width
.max-w-40rem {
  max-width: 40rem;
}

.max-w-70rem {
  max-width: 70rem;
}

//Calculated width
.w-calc-20_minus_1_5rem {
  width: calc(20% - 1.5rem);
}

.w-calc-25_minus_0_844rem {
  width: calc(25% - 0.844rem);
}

.w-calc-33_minus_1rem {
  width: calc(33% - 1rem);
}

.w-calc-33_3_minus_1_3rem {
  width: calc(33.3% - 1.3rem);
}

.w-calc-45_minus_0_5rem {
  width: calc(45% - 0.5rem);
}

.w-calc-50_minus_0_25rem {
  width: calc(50% - 0.25rem);
}

.w-calc-50_minus_0_5rem {
  width: calc(50% - 0.5rem);
}

.w-calc-50_minus_0_625rem {
  width: calc(50% - 0.625rem);
}

.w-calc-50_minus_0_75rem {
  width: calc(50% - 0.75rem);
}

.w-calc-50_minus_0_875rem {
  width: calc(50% - 0.875rem);
}

.w-calc-50_minus_1rem {
  width: calc(50% - 1rem);
}

.w-calc-50_minus_4_375rem {
  width: calc(50% - 4.375rem);
}

.w-calc-55_minus_0_5rem {
  width: calc(55% - 0.5rem);
}

.w-calc-100_minus_1rem {
  width: calc(100% - 1rem);
}

.w-calc-100_minus_8rem {
  width: calc(100% - 8rem);
}

.w-calc-100_minus_10rem {
  width: calc(100% - 10rem);
}

.w-calc-100_minus_10_5rem {
  width: calc(100% - 10.5rem);
}

.w-calc-100_minus_20rem {
  width: calc(100% - 20rem);
}

//height

.h-90 {
  height: 90%;
}

.h-100 {
  height: 100%;
}

.h-80vh {
  height: 80vh;
}
.h-100vh {
  height: 100vh;
}

.h-0_5rem {
  height: 0.5rem;
}

.h-0_75rem {
  height: 0.75rem;
}

.h-1rem {
  height: 1rem;
}

.h-1_125rem {
  height: 1.125rem;
}

.h-1_25rem {
  height: 1.25rem;
}

.h-1_4rem {
  height: 1.4rem;
}

.h-1_5rem {
  height: 1.5rem;
}

.h-1_75rem {
  height: 1.75rem;
}

.h-1_875rem {
  height: 1.875rem;
}

.h-2rem {
  height: 2rem;
}

.h-2_25rem {
  height: 2.25rem;
}

.h-2_375rem {
  height: 2.375rem;
}

.h-2_5rem {
  height: 2.5rem;
}

.h-2_75rem {
  height: 2.75rem;
}

.h-3rem {
  height: 3rem;
}

.h-3_5rem {
  height: 3.5rem;
}

.h-4rem {
  height: 4rem;
}

.h-4_31rem {
  height: 4.31rem;
}

.h-4_375rem {
  height: 4.375rem;
}

.h-4_7rem {
  height: 4.7rem;
}

.h-4_875rem {
  height: 4.875rem;
}

.h-5_125rem {
  height: 5.125rem;
}

.h-6_56rem {
  height: 6.56rem;
}

.h-7rem {
  height: 7rem;
}

.h-7_5rem {
  height: 7.5rem;
}

.h-8rem {
  height: 8rem;
}

.h-8_375rem {
  height: 8.375rem;
}

.h-9rem {
  height: 9rem;
}

.h-11rem {
  height: 11rem;
}

.h-14rem {
  height: 14rem;
}

.h-15rem {
  height: 15rem;
}

.h-18rem {
  height: 18rem;
}

.h-25rem {
  height: 25rem;
}

.h-26_5rem {
  height: 26.5rem;
}

.h-27_2rem {
  height: 27.2rem;
}

.h-30rem {
  height: 30rem;
}

.h-32_65rem {
  height: 32.65rem;
}

.h-35rem {
  height: 35rem;
}

.min-h-40vh {
  min-height: 40vh;
}

.min-h-60vh {
  min-height: 60vh;
}

//Minimal height
.min-h-100vh {
  min-height: 100vh;
}

.min-h-4_375rem {
  min-height: 4.375rem;
}

.min-h-2_25rem {
  min-height: 2.25rem;
}

//Maximal height
.max-h-15rem {
  max-height: 15rem;
}

.max-h-25rem {
  max-height: 25rem;
}

.max-h-75vh {
  max-height: 75vh;
}

.max-h-80vh {
  max-height: 80vh;
}

.max-h-90vh {
  max-height: 90vh;
}

//Calculated height

.h-calc-100vh_minus_10rem {
  height: calc(100vh - 10rem);
}

.h-calc-100vh_minus_20rem {
  height: calc(100vh - 20rem);
}

.h-calc-100-8rem {
  height: calc(100% - 8rem);
}

//Calculated max height
.max-h-calc-80vh_minus_13rem {
  max-height: calc(80vh - 13rem);
}
.max-h-calc-100vh_minus_25rem {
  max-height: calc(100vh - 25rem);
}
