.settings-modal-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
  border-radius: 0.625rem;
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  color: $text-color-main;
  letter-spacing: 0.0062rem;
  border: 1px solid $border-color-main;
  padding-left: 3rem;
  &:focus {
    border: 1px solid $border-color-active;
    outline: none;
  }
  &::placeholder {
    color: $placeholder-color;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    letter-spacing: 0.0062rem;
  }
}

.agents-modal-table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;

  thead th {
    background-color: $bg-secondary;
  }
  td:last-child {
    padding-right: 2rem;
  }

  th:first-child,
  td:first-child {
    padding-left: 1rem;
    text-align: left;
  }

  th,
  td {
    padding-left: 3.375rem;
    text-align: left;
  }

  th {
    height: 3rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    color: $text-color-6b7280;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }

  td {
    height: 3.37rem;
    font-size: 0.875rem;
    color: $text-color-111928;
    font-family: Inter, sans-serif;
    border-top: 1px solid $border-color-e5e7eb;
    font-weight: 500;
  }
  .leads-table-name {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: $text-color-regular;
    letter-spacing: 0.0125rem;
  }
}

.modal-checkbox {
  width: 1rem;
  height: 1rem;
  border-radius: 0.185rem;
  border: 1px solid $checkbox-border-color;
  appearance: none;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: $background-color-1e75ff;
    border: none;
  }

  &:checked::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    clip-path: polygon(28% 45%, 20% 55%, 46% 77%, 82% 35%, 73% 26%, 45% 60%);
    background-color: $background-color-f9fafb;
    position: absolute;
  }
}

.tags-checkbox {
  width: 1rem;
  height: 1rem;
  border-radius: 0.185rem;
  border: 1px solid $checkbox-border-color;
  appearance: none;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: $background-color-1e75ff;
    border: 1px solid #a4cafe;
  }

  &:checked::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    clip-path: polygon(28% 45%, 20% 55%, 46% 77%, 82% 35%, 73% 26%, 45% 60%);
    background-color: $background-color-f9fafb;
    position: absolute;
  }
}
