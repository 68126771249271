.settings-textarea {
  padding: 0.75rem 1rem;
  border-radius: 1.5rem;
  border: 1px solid $border-color-main;
  background-color: $bg-secondary;
  font-size: 0.875rem;
  font-weight: 400;
  color: $text-color-main;
  font-family: Inter, sans-serif;
  resize: none;

  &::placeholder {
    color: $text-header-description;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }

  &:focus {
    border-color: $border-color-input-active; // Change this to the color you want for the active border
    outline: none; // Optionally, you can remove the default focus outline
  }
}

.settings-header-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1rem;
  border-radius: 0.75rem;
  text-decoration: none;

  span {
    font-size: 0.875rem;
    font-weight: 475;
    font-family: Inter, sans-serif;
    color: $text-color-6b7280;
  }

  &.active {
    background-color: $background-color-1e75ff;
    span {
      color: $text-color-white;
    }
  }
  &:hover {
    background-color: $background-color-1e75ff;
    cursor: pointer;
    span {
      color: $text-color-white;
    }
  }
}

.settings-agent-bio-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 35rem;

  thead th {
    background-color: $background-table-header-color;
    height: 3.125rem;
    border-radius: 0.5rem;
  }
  /*th:first-child {

  }
  th:last-child {
    text-align: right;
  }
  td:last-child {
    text-align: right;
  }*/

  th,
  td {
    padding-left: 1rem;
    text-align: left;
  }

  th {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    color: $text-header-description;
    text-transform: uppercase;
  }

  td {
    height: 4.5rem;
    font-size: 0.875rem;
    color: $text-color-main;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    border-bottom: 1px solid $border-color-main;
  }
  .agent-bio-table-name {
    font-size: 0.875rem;
    color: $text-color-171725;
    font-family: Inter, sans-serif;
    font-weight: 400;
    border-bottom: 1px solid $border-color-main;
  }
}

.payment-details-input {
  height: 3rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-main;
  background-color: $bg-white;
  font-size: 0.875rem;
  font-weight: 400;
  color: $text-color-main;
  font-family: Inter, sans-serif;

  &::placeholder {
    color: $text-header-description;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }

  &:focus {
    border-color: $border-color-input-active;
    outline: none;
  }
}

.payment-details-input-label {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.31rem;
  color: $text-color-111928;
  font-family: Inter, sans-serif;
  padding-bottom: 0.5rem;
}

.payment-details-textarea {
  height: 13rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-main;
  background-color: $bg-white;
  font-size: 0.875rem;
  font-weight: 400;
  color: $text-color-main;
  font-family: Inter, sans-serif;
  resize: none;

  &::placeholder {
    color: $text-header-description;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }

  &:focus {
    border-color: $border-color-input-active; // Change this to the color you want for the active border
    outline: none; // Optionally, you can remove the default focus outline
  }
}

.payment-method-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-main;
  background-color: $background-light-grey-color;
  .payment-method-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .payment-method-checkbox-row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      .payment-method-checkbox {
        height: 1rem;
        width: 1rem;
        border-radius: 0.5rem;
        border: 1px solid $border-color-main;
        background-color: $bg-white;
        margin-top: 0.1rem;
        margin-right: 0.5rem;
      }
    }
  }
  &.active {
    border-color: $border-color-input-active;
    .payment-method-info {
      .payment-method-checkbox-row {
        .payment-method-checkbox {
          background-color: $background-light-grey-color;
          border: 3px solid $border-color-input-active;
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
    border-color: $border-color-input-active;
    .payment-method-info {
      .payment-method-checkbox-row {
        .payment-method-checkbox {
          background-color: $background-light-grey-color;
          border: 3px solid $border-color-input-active;
        }
      }
    }
  }
}

.add-payment-method {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-main;
  background-color: $background-light-grey-color;
}
