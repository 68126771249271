.auth-form {
  border-radius: 1.25rem;
  padding: 2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.form-row {
  width: 100%;
}

.form-item {
  position: relative;
}

.form-control {
  width: 100%;
  height: 3rem;
  border-radius: 0.625rem;
  padding: 0 1.25rem;
  font-size: 0.875rem;
  color: $text-color-main;
  letter-spacing: 0.0062rem;
  border: 1px solid $border-color-main;
  &::placeholder {
    color: $placeholder-color;
  }
  &:focus {
    border: 1px solid $border-color-active;
    outline: none;
  }
  &.error-border {
    border: 1px solid $error-color;
  }
}

.textarea-control {
  width: 100%;
  height: 8.125rem;
  border-radius: 0.625rem;
  padding: 1.25rem;
  font-size: 0.875rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: $text-color-main;
  letter-spacing: 0.0062rem;
  border: 1px solid $border-color-main;
  resize: none;
  &::placeholder {
    color: $placeholder-color;
  }
  &:focus {
    border: 1px solid $border-color-active;
    outline: none;
  }
  &.error-border {
    border: 1px solid $error-color;
  }
}

.form-error {
  color: $error-color;
  font-size: 0.75rem;
  position: absolute;
  bottom: -0.9rem;
  letter-spacing: 0.006rem;
}

.title-container {
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  z-index: 1;
  background-color: $background-white;
  padding: 0 0.35rem;
}

.form-title {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.006rem;
  line-height: normal;
}

.phone-code {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3rem;
  border-radius: 0.625rem;
  font-size: 0.8rem;
  color: $form-title-color;
  letter-spacing: 0.0062rem;
  border: 1px solid $border-color-main;
}
.phone-code-active {
  border: 1px solid $border-color-active;
}

.dropdown-country-menu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 20rem;
  min-height: 1rem;
  overflow: scroll;
  width: 100%;
  position: absolute;
  top: 3rem;
  z-index: 2;
}

.dropdown-country-item {
  width: 100%;
  background-color: $background-white;
  padding: 0.5rem 1.2rem;
  border-bottom: 1px solid $border-color-main;
  &:hover {
    cursor: pointer;
    background-color: $background-color-hover;
  }
  &:last-child {
    border-bottom: none;
  }
  span {
    font-size: 0.875rem;
    letter-spacing: 0.006rem;
    color: $text-color-main;
  }
}

.country-dropdown-icon {
  position: absolute;
  top: 0.7rem;
  right: 1.1rem;
  cursor: pointer;
}

.email-suffix-container {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  span {
    font-size: 0.875rem;
    letter-spacing: 0.006rem;
    color: $text-color-main;
  }
}

.show-password-container {
  position: absolute;
  top: 1rem;
  right: 1.1rem;
  cursor: pointer;
}

.checkbox-form {
  position: relative;
  .checkbox-input {
    position: relative;
    overflow: hidden;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1rem;
    height: 1rem;
    border: 1px solid $border-color-main;
    border-radius: 0.2rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  label {
    display: flex;
    align-items: center;
    color: $checkbox-title-color;
    font-size: 0.875rem;
    letter-spacing: 0.006rem;
  }
  input {
    margin-right: 0.625rem;
  }
}

.checkbox-active {
  position: absolute;
  top: -0.12rem;
  left: -0.12rem;
}

.forgot-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  &:hover {
    cursor: pointer;
  }
}

.forgot-password-block {
  padding: 3.25rem 2.625rem;
  border-radius: 1.25rem;
}
