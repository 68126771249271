//background colors
$background-color: #fafafb;
$bg-white: #ffffff;
$bg-secondary: #f9fafb;
$primary-color: #ffdd02;
$background-white: #ffffff;
$background-color-active: #50b5ff;
$background-color-hover: #f1f1f5;
$background-light-grey-color: #fafafb;
$background-table-header-color: #f9fafb;
$background-leads-filter-checkpoint: #f3f4f6;
$background-campaign-action-buttons: #f9fafb;
$background-header-secondary: #f8f9fb;
$background-convert-secondary: #f3f4f6;
$background-badge-color: #eeeff2;
$background-message-primary-color: #1651cf;
$background-pastel-mint-color: #ebfbf1;
$background-misty-mint-color: #def7ec;
$background-soft-wheat-color: #fdf6b2;
$background-color-3dd598: #3dd598;
$background-color-0062ff: #0062ff;
$background-color-979797: #979797;
$background-color-a461d8: #a461d8;
$background-color-f6effb: #f6effb;
$background-color-ffc542: #ffc542;
$background-color-82c43c: #82c43c;
$background-color-3dd598: #3dd598;
$background-color-f1f1f5: #f1f1f5;
$background-color-50b5ff: #50b5ff;
$background-color-181b1f: #181b1f;
$background-color-fffefe: #fffefe;
$background-color-2b1943: #2b1943;
$background-color-0ebff1: #0ebff1;
$background-color-85d799: #85d799;
$background-color-1e75ff: #1e75ff;
$background-color-e0effd: #e0effd;
$background-color-f9fafb: #f9fafb;
$background-color-f3f4f6: #f3f4f6;
$background-color-ebf5ff: #ebf5ff;
$background-color-ffcc00: #ffcc00;
$background-color-075b59: #075b59;
$background-color-1e92f4: #1e92f4;
$background-color-ff3b30: #ff3b30;
$background-color-34c759: #34c759;
$background-color-ff974a: #ff974a;
$background-color-1067db: #1067db;
$background-color-ff0000: #ff0000;
$background-color-ff0044: #ff0044;
$background-color-8000ffc4: #8000ffc4;
$background-color-ca10db: #ca10db;
$background-color-6814f10f: #6814f10f;

//error color
$error-color: #fc5a5a;

//border colors
$border-color-main: #e2e2ea;
$border-color-active: #50b5ff;
$border-color-secondary: #e2e2ea;
$border-color-light-gray: #f1f1f5;
$border-color-leads-filter-button: #d1d5db;
$border-color-input-active: #1e75ff;
$border-color-template-active: #0a090b;
$border-color-e5e7eb: #e5e7eb;
$border-color-eaedf2: #eaedf2;
$border-color-f8fafb: #f8fafb;
$border-color-e1e1e1: #e1e1e1;
$border-color-1e92f4: #1e92f4;

//font colors
$text-color-main: #44444f;
$text-color-title: #262628;
$text-color-secondary: #92929d;
$text-color-h2: #171725;
$text-color-description: #696974;
$text-color-h5-primary: #1f2937;
$placeholder-color: #b5b5be;
$form-title-color: #50b5ff;
$checkbox-title-color: #696974;
$checked-text-color: #016626;
$text-color-regular: #171725;
$dynamic-header-text-color: #1a0d3f;
$dynamic-header-active-text-color: #50b5ff;
$price-text-color: #0a090b;
$inter-font-text-color: #5f5f61;
$inter-font-medium-text-color: #4f4d55;
$active-sidebar-item-color: #0062ff;
$text-color-inter-secondary: #9e9e9e;
$text-color-leads-filter-button: #6b7280;
$text-color-white: #ffffff;
$social-media-text-color: #1a0d3f;
$text-header-title-h2: #121928;
$text-header-secondary-button-color: #121928;
$text-campaign-action-button-title: #121928;
$text-header-description: #6b7280;
$text-inter-regular: #111928;
$text-inter-regular-secondary: #666d80;
$text-inter-color: #0a090b;
$text-inter-regular-1rem: #0d0d12;
$text-abril-fatface-color: #808897;
$text-message-inter-regular: #f5f8fd;
$text-template-description-color: #2d2b32;
$text-inter-bold-h3-color: #2d2b32;
$text-nunito-bold: #111928;
$text-color-approved: #03543f;
$text-color-pending: #723b13;
$text-color-soft-silver: #fafafb;
$text-inter-regular-description: #808897;
$text-hover-primary: #0062ff;
$text-color-clicks: #b4b4b4;
$text-color-black: #000000;
$text-color-454545: #454545;
$text-color-52a86e: #52a86e;
$text-color-5f5f61: #5f5f61;
$text-color-262628: #262628;
$text-color-impressions: rgba(104, 20, 241, 0.75);
$text-color-warm-leads: #f34d4d;
$text-color-conversations: #4dd5f3;
$text-color-replies: #47ac34;
$text-color-e73f3f: #e73f3f;
$text-color-0062ff: #0062ff;
$text-color-696974: #696974;
$text-color-1751d0: #1751d0;
$text-color-fafafb: #fafafb;
$text-color-50b5ff: #50b5ff;
$text-color-fcfdfe: #fcfdfe;
$text-color-111010: #111010;
$text-color-6b7280: #6b7280;
$text-color-111928: #111928;
$text-color-171725: #171725;
$text-color-1e429f: #1e429f;
$text-color-0e9f6e: #0e9f6e;
$text-color-9ca3af: #9ca3af;
$text-color-ffc542: #ffc542;
$text-color-888e9e: #888e9e;
$text-color-555f75: #555f75;
$text-color-0f0f10: #0f0f10;
$text-color-323741: #323741;
$text-color-8c8c8c: #8c8c8c;
$text-color-8c4df3: #8c4df3;
$text-color-f34d4d: #f34d4d;
$text-color-4dd5f3: #4dd5f3;
$text-color-47ac34: #47ac34;

//button colors
$button-color-primary: #0062ff;
$button-color-light-primary: #50b5ff;
$button-color-primary-disabled: #50b5ff;
$button-color-secondary: #f1f1f5;
$button-text-color-primary: #fafafb;
$button-text-color-secondary: #696974;
$button-leads-filter-active: #1a56db;
$button-component-header-primary-color: #1f75ff;
$button-component-header-primary-border-color: #1b56db;
$button-component-header-secondary-border-color: #e4e7eb;
$button-text-preview-primary: #1751d0;
$button-roboto-primary-color: #1e75ff;
$button-color-1e75ff: #1e75ff;

//checkbox
$leads-filters-checkbox-background-color: #f9fafb;
$leads-filters-checkbox-active-color: #1a56db;
$rounded-checkbox-active-color: #4d57ef;
$checkbox-border-color: #b5b5be;
$checkbox-color-3dd598: #3dd598;

//links
$link-primary-color: #0062ff;
$link-secondary-color: #50b5ff;

//progressbar
$progress-bar-active-color: #50b5ff;
$progress-bar-inactive-color: #e5e9ee;

//modal
$modal-background-color: #ffffff;
$modal-layer-background-color: rgba(23, 23, 37, 0.3);
