.dashboard-conversation-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 35rem;

  thead th {
    background-color: $background-light-grey-color;
    height: 2.375rem;
  }
  /*th:first-child {
  
    }*/
  th:last-child {
    text-align: right;
  }
  td:last-child {
    text-align: right;
  }

  th,
  td {
    padding-left: 1rem;
    text-align: left;
  }

  th {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 0.6875rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    border-radius: 0.625rem;
  }

  td {
    height: 3.375rem;
    font-size: 0.875rem;
    color: $text-color-main;
    font-family: Roboto, sans-serif;
    font-weight: 475;
    border-bottom: 1px solid $border-color-main;
    padding: 1rem 0;
  }
}

.dashboard-search-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.375rem;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  color: $text-color-main;
  letter-spacing: 0.0062rem;
  border: 1px solid $border-color-main;
  padding-left: 3rem;
  &:focus {
    border: 1px solid $border-color-active;
    outline: none;
  }
  &::placeholder {
    color: $text-color-secondary;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    letter-spacing: 0.0125rem;
  }
}
