@import '../constants/colors';

.progress-bar {
  height: 0.5rem;
  width: 100%;
  background-color: $progress-bar-inactive-color;
  border-radius: 0.25rem;
}

.progress-bar-active {
  height: 0.5rem;
  width: 100%;
  background-color: $progress-bar-active-color;
  border-radius: 0.25rem;
}

.additional-info-container {
  border-radius: 3rem;
  border: 1px solid $border-color-main;
  box-shadow: 3px -5px 40px 0px #cdcdd41a;
}

.add-email-container {
  border-radius: 2rem;
  border: 1px dashed $border-color-main;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
