@import '../constants//colors';

.leads-search-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.375rem;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  color: $text-color-main;
  letter-spacing: 0.0062rem;
  border: 1px solid $border-color-main;
  padding-left: 3rem;
  &:focus {
    border: 1px solid $border-color-active;
    outline: none;
  }
  &::placeholder {
    color: $text-color-secondary;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    letter-spacing: 0.0125rem;
  }
}

.leads-table {
  width: 100%;
  border-collapse: collapse;

  thead th {
    background-color: $background-light-grey-color;
  }
  th:first-child {
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    max-width: 4rem;
  }

  th:last-child {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    max-width: 4rem;
  }

  th,
  td {
    padding-left: 3.375rem;
    text-align: left;
  }

  th {
    height: 2.375rem;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 0.6875rem;
    color: $text-color-main;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }

  td {
    height: 4.5rem;
    font-size: 0.875rem;
    letter-spacing: 0.00625rem;
    color: $text-color-main;
    font-family: Roboto, sans-serif;
    border-bottom: 1px solid $border-color-main;
  }
  .leads-table-name {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: $text-color-regular;
    letter-spacing: 0.0125rem;
  }
}

.create-campaign-leads-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 35rem;

  thead th {
    background-color: $background-table-header-color;
    height: 3.125rem;
  }

  td:last-child,
  th:last-child {
    text-align: left;
    padding-right: 2rem;
    max-width: 12rem;
    width: 12rem;
  }

  th,
  td {
    padding-left: 1rem;
    text-align: left;
  }

  th {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    color: $text-header-description;
    text-transform: uppercase;
    border-bottom: 1px solid $border-color-main;
  }

  td {
    height: 3.375rem;
    font-size: 0.875rem;
    color: $text-color-main;
    font-family: Inter, sans-serif;
    font-weight: 475;
    border-bottom: 1px solid $border-color-main;
  }
  .leads-table-name {
    font-size: 0.875rem;
    color: $text-color-main;
    font-family: Inter, sans-serif;
    font-weight: 475;
    border-bottom: 1px solid $border-color-main;
  }
}

.leads-filter-checkpoint-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0.5rem;
  width: 100%;
  margin: 0.187rem 0;

  label {
    padding-left: 0.5rem;
    width: 100%;
    height: 100%;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 475;
    color: $text-color-leads-filter-button;
    &:hover {
      cursor: pointer;
    }
  }
  &.active {
    background-color: $background-leads-filter-checkpoint;
    border-radius: 0.5rem;
    &:hover {
      opacity: 0.8;
    }
  }
  &:hover {
    background-color: $background-leads-filter-checkpoint;
    border-radius: 0.5rem;
  }
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: $leads-filters-checkbox-background-color;
    margin: 0;
    color: currentColor;
    width: 1.15rem;
    height: 1.15rem;
    border: 0.03rem solid $border-color-main;
    border-radius: 0.25rem;
    transform: translateY(-0.075rem);
    display: grid;
    place-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65rem;
    height: 0.65rem;
    clip-path: polygon(10% 55%, 0 65%, 40% 100%, 100% 10%, 90% 0%, 37% 79%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: $leads-filters-checkbox-active-color;
  }

  input[type='checkbox']:checked {
    background-color: $leads-filters-checkbox-active-color;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
    background-color: $background-white;
  }
}

.leads-filter-radio-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0.5rem;
  width: 100%;
  margin: 0.187rem 0;

  label {
    padding-left: 0.5rem;
    width: 100%;
    height: 100%;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding-bottom: 0.05rem;
    font-weight: 475;
    color: $text-color-leads-filter-button;
    &:hover {
      cursor: pointer;
    }
  }
  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: $leads-filters-checkbox-background-color;
    margin: 0;
    width: 1rem;
    height: 0.95rem;
    border: 0.031rem solid $border-color-main;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  input[type='radio']::before {
    content: '';
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    transform: scale(1);
    transition: 120ms transform ease-in-out;
    background-color: $leads-filters-checkbox-background-color;
  }

  input[type='radio']:checked::before {
    transform: scale(1.1);
    border: 0.218rem solid $leads-filters-checkbox-active-color;
  }
}

.pagination-item {
  .pagination-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background-white;
    border-radius: 0.75rem;
    width: 2.375rem;
    height: 2.375rem;
    span {
      font-family: Roboto, sans-serif;
      font-weight: 400;
      color: $text-color-secondary;
      letter-spacing: 0.00625rem;
      font-size: 0.875rem;
    }
  }
  button {
    border: 1px solid $border-color-main;
  }
  &.active {
    .pagination-item-container {
      span {
        color: $active-sidebar-item-color;
        font-weight: 700;
      }
    }
  }
  &:hover {
    .pagination-item-container {
      span {
        color: $active-sidebar-item-color;
        font-weight: 700;
      }
    }
  }
  .pagination-ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.375rem;
    height: 2.375rem;
    color: $text-color-secondary;
  }
}

.campaign-input {
  height: 2.625rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-main;
  background-color: $bg-secondary;
  font-size: 0.875rem;
  font-weight: 400;
  color: $text-color-main;
  font-family: Inter, sans-serif;

  &::placeholder {
    color: $text-header-description;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }

  &:focus {
    border-color: $border-color-input-active;
    outline: none;
  }
  &.error {
    border: 1px solid $error-color;
    outline: none;
  }
}

.campaign-textarea {
  height: 15.58rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-main;
  background-color: $bg-secondary;
  font-size: 0.875rem;
  font-weight: 400;
  color: $text-color-main;
  font-family: Inter, sans-serif;
  resize: none;

  &::placeholder {
    color: $text-header-description;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }

  &:focus {
    border-color: $border-color-input-active; // Change this to the color you want for the active border
    outline: none; // Optionally, you can remove the default focus outline
  }
  &.error {
    border: 1px solid $error-color;
    outline: none;
  }
}

.campaign-label-input {
  font-size: 0.875rem;
  font-weight: 475;
  line-height: 1.31rem;
  color: $text-inter-regular;
  font-family: Inter, sans-serif;
  padding-bottom: 0.5rem;
}

.campaign-convert-input {
  height: 3.25rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  width: 100%;
  min-width: 15rem;
  border: 1px solid $border-color-main;
  background-color: $bg-secondary;
  font-size: 1rem;
  font-weight: 400;
  color: $text-color-main;
  font-family: Inter, sans-serif;

  &::placeholder {
    color: $text-header-description;
    font-size: 1rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }

  &:focus {
    border-color: $border-color-input-active;
    outline: none;
  }
}

.checkbox-rounded {
  width: 1.25rem;
  height: 1.25rem;
  background-color: $bg-white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid $border-color-main;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
}

.checkbox-rounded:checked {
  background-color: $rounded-checkbox-active-color;
  border: 1px solid $rounded-checkbox-active-color;
}

.checkbox-rounded:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(45deg) translate(-100%, -20%);
  width: 4px;
  height: 8px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.campaign-template-textarea {
  height: 8.75rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-main;
  background-color: $bg-secondary;
  font-size: 1rem;
  font-weight: 400;
  color: $text-color-main;
  font-family: Inter, sans-serif;
  resize: none;

  &::placeholder {
    color: $text-header-description;
    font-size: 1rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }

  &:focus {
    border-color: $border-color-input-active;
    outline: none;
  }
}
