@import '../constants/colors';

.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: $bg-white;
  transition: width 0.1s ease-in-out; // Smooth transition for width changes
  z-index: 2;
  border-right: 1px solid $border-color-main;

  &.w-15_625rem {
    width: 15.625rem;
  }

  &.w-4rem {
    width: 4rem;
  }

  .change-width-animation {
    transition: width 0.2s ease-in-out; // Smooth transition for child elements
  }

  .sidebar-image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .sidebar-logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    height: 2.75rem;

    &.visible {
      opacity: 1;
    }

    &.hidden {
      opacity: 0;
    }
  }

  .sidebar-item-container {
    position: relative;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;

      .sidebar-icon path {
        fill: $active-sidebar-item-color;
      }

      span {
        color: $active-sidebar-item-color;
        font-weight: 600;
      }
    }

    &.active {
      .sidebar-icon path {
        fill: $active-sidebar-item-color;
      }

      span {
        color: $active-sidebar-item-color;
        font-weight: 600;
      }
    }

    .sidebar-item-active {
      position: absolute;
      height: 2rem;
      width: 0.1875rem;
      left: 0;
      top: 1rem;
      background-color: $active-sidebar-item-color;
      border-top-right-radius: 0.1875rem;
      border-bottom-right-radius: 0.1875rem;
      transition: all 0.2s ease-in-out; // Smooth movement of the active indicator
    }
  }
}
