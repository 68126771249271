@import './init';

// Reset padding and margins for all elements
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Bold.ttf') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Medium.ttf') format('woff2');
  font-weight: 475;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Bold.ttf') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf')
    format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('../assets/fonts/Archivo/Archivo_Condensed-Regular.ttf')
    format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('../assets/fonts/Archivo/Archivo_Condensed-SemiBold.ttf')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  color: $text-color-main;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
