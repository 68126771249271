@import '../constants/colors';

.flex-1 {
  flex: 1;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.d-flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.d-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.d-flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.d-flex-end-start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.d-flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.d-flex-start-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.d-flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.d-flex-start-between-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.d-flex-center-around-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.d-flex-center-end-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.d-flex-end-end-row {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
}

.d-flex-end-start-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
}

.d-flex-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.d-flex-start-baseline-row {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: row;
}

.d-flex-start-start-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.d-flex-start-center-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.d-flex-center-start-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.d-flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.f-wrap {
  flex-wrap: wrap;
}

//Overflow
.overflow-h {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}

//Colors

.bg-white {
  background-color: $background-white;
}

.bg-light-grey {
  background-color: $background-light-grey-color;
}

.bg-secondary {
  background-color: $bg-secondary;
}

.bg-header {
  background-color: $background-header-secondary;
}

.bg-badge {
  background-color: $background-badge-color;
}

.bg-pastel-mint {
  background-color: $background-pastel-mint-color;
}

.bg-misty-mint {
  background-color: $background-misty-mint-color;
}

.bg-soft-wheat {
  background-color: $background-soft-wheat-color;
}

.bg-primary-message {
  background-color: $background-message-primary-color;
}

.bg-0062ff {
  background-color: $background-color-0062ff;
}

.bg-3dd598 {
  background-color: $background-color-3dd598;
}

.bg-a461d8 {
  background-color: $background-color-a461d8;
}

.bg-f6effb {
  background-color: $background-color-f6effb;
}

.bg-ffc542 {
  background-color: $background-color-ffc542;
}

.bg-82c43c {
  background-color: $background-color-82c43c;
}

.bg-f1f1f5 {
  background-color: $background-color-f1f1f5;
}

.bg-fffefe {
  background-color: $background-color-fffefe;
}

.bg-2b1943 {
  background-color: $background-color-2b1943;
}

.bg-0ebff1 {
  background-color: $background-color-0ebff1;
}

.bg-85d799 {
  background-color: $background-color-85d799;
}

.bg-1e75ff {
  background-color: $background-color-1e75ff;
}

.bg-e0effd {
  background-color: $background-color-e0effd;
}

.bg-ebf5ff {
  background-color: $background-color-ebf5ff;
}

.bg-ffcc00 {
  background-color: $background-color-ffcc00;
}

.bg-075b59 {
  background-color: $background-color-075b59;
}

.bg-1e92f4 {
  background-color: $background-color-1e92f4;
}

.bg-ff3b30 {
  background-color: $background-color-ff3b30;
}

.bg-34c759 {
  background-color: $background-color-34c759;
}

.bg-ff974a {
  background-color: $background-color-ff974a;
}

.bg-1067db {
  background-color: $background-color-1067db;
}

.bg-ff0000 {
  background-color: $background-color-ff0000;
}

.bg-ff0044 {
  background-color: $background-color-ff0044;
}

.bg-8000ffc4 {
  background-color: $background-color-8000ffc4;
}

.bg-8000ffc4 {
  background-color: $background-color-8000ffc4;
}

.bg-ca10db {
  background-color: $background-color-ca10db;
}

.bg-6814f10f {
  background-color: $background-color-6814f10f;
}
.bg-modal {
  background-color: $modal-layer-background-color;
}

//outline
.outline-none {
  outline: none;
}

//borders

//border-none
.b-none {
  border: none;
}

.border-template-active {
  &:hover {
    outline: 1px solid $border-color-template-active;
    border: 1px solid $border-color-template-active;
    cursor: pointer;
  }
}

.b-1 {
  border: 1px solid $border-color-main;
}

.b-1-light-gray {
  border: 1px solid $border-color-light-gray;
}

.b-1-eaedf2 {
  border: 1px solid $border-color-eaedf2;
}

//border-right
.br-1 {
  border-right: 1px solid $border-color-main;
}

.br-1-secondary {
  border-right: 1px solid $border-color-secondary;
}

//border-left
.bl-1 {
  border-left: 1px solid $border-color-main;
}

.bl-1-l-gray {
  border-left: 1px solid $border-color-light-gray;
}

//border-bottom
.bb-1 {
  border-bottom: 1px solid $border-color-main;
}

.bb-1-l-gray {
  border-bottom: 1px solid $border-color-light-gray;
}

.bb-1-e1e1e1 {
  border-bottom: 1px solid $border-color-e1e1e1;
}

.bb-1-1e92f4 {
  border-bottom: 1px solid $border-color-1e92f4;
}

//border-top
.bt-1 {
  border-top: 1px solid $border-color-main;
}

//border-radius
.b-radius-0_25rem {
  border-radius: 0.25rem;
}

.b-radius-0_313rem {
  border-radius: 0.313rem;
}

.b-radius-0_375rem {
  border-radius: 0.375rem;
}

.b-radius-0_5rem {
  border-radius: 0.5rem;
}

.b-radius-0_625rem {
  border-radius: 0.625rem;
}

.b-radius-0_75rem {
  border-radius: 0.75rem;
}

.b-radius-0_875rem {
  border-radius: 0.875rem;
}

.b-radius-1rem {
  border-radius: 1rem;
}

.b-radius-1_125rem {
  border-radius: 1.125rem;
}

.b-radius-1_25rem {
  border-radius: 1.25rem;
}

.b-radius-1_5rem {
  border-radius: 1.5rem;
}

.b-radius-2_125rem {
  border-radius: 2.125rem;
}

.b-radius-3rem {
  border-radius: 3rem;
}

.b-radius-3_5rem {
  border-radius: 3.5rem;
}

.b-radius-50 {
  border-radius: 50%;
}

//border top left radius
.btl-radius-0_875rem {
  border-top-left-radius: 0.875rem;
}

.btl-radius-1_125rem {
  border-top-left-radius: 1.125rem;
}

.btl-radius-3rem {
  border-top-left-radius: 3rem;
}

//border top right radius
.btr-radius-0_875rem {
  border-top-right-radius: 0.875rem;
}

.btr-radius-3rem {
  border-top-right-radius: 3rem;
}

//border bottom left radius
.bbl-radius-1_25rem {
  border-bottom-left-radius: 1.25rem;
}

.bbl-radius-0_875rem {
  border-bottom-left-radius: 0.875rem;
}

.bbl-radius-3rem {
  border-bottom-left-radius: 3rem;
}

//border bottom right radius
.bbr-radius-1_25rem {
  border-bottom-right-radius: 1.25rem;
}

.bbr-radius-3rem {
  border-bottom-right-radius: 3rem;
}

//hover
.hovered {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 0.3s;
  }
}

//opacity
.opacity-0_5 {
  opacity: 0.5;
}
.opacity-0_7 {
  opacity: 0.7;
}

//img
.obj-fit-cover {
  object-fit: cover;
}

//shadows
.shadow-box-1 {
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.shadow-box-2 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.shadow-box-3 {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
}

.shadow-box-4 {
  box-shadow: 0px 2px 12px -1px rgba(10, 9, 11, 0.1);
}

.shadow-box-5 {
  box-shadow: 0px 2px 2px -1px rgba(10, 9, 11, 0.04);
}

.shadow-box-6 {
  box-shadow: 0px 0px 0px 1px rgba(10, 9, 11, 0.05);
}

.shadow-box-7 {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.shadow-box-8 {
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 79, 0.1);
}

.shadow-box-9 {
  box-shadow: 0px 1px 2px -1px #0000001a;
  box-shadow: 0px 1px 3px 0px #0000001a;
}

.shadow-box-10 {
  box-shadow: 0px -6px 40px 0px #1b1b1c33;
}

.shadow-box-11 {
  box-shadow: 0px 4px 6px -2px #1823220d;
  box-shadow: 0px 12px 16px -4px #18232214;
}

.shadow-box-12 {
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 79, 0.2);
}

.shadow-box-bottom {
  box-shadow: 0 4px 4px -2.5px rgba(0, 0, 0, 0.25);
}

//Gradient
.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.7)
  );
}

//scroll
.scroll-auto {
  overflow-y: auto;
}

//flex-grow
.flex-grow-1 {
  flex-grow: 1;
}
