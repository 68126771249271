.rotate-180deg {
  transform: rotate(180deg);
}

.transition-0_5s {
  transition: 0.5s;
}

.loader {
  width: fit-content;
  font-weight: bold;
  clip-path: inset(0 1.8ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content: 'Generating initial message . . .';
}
@keyframes l4 {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
