.converstaion-filter-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.375rem;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  color: $text-color-main;
  letter-spacing: 0.0062rem;
  border: 1px solid $border-color-main;
  padding-left: 1rem;
  &:focus {
    border: 1px solid $border-color-active;
    outline: none;
  }
  &::placeholder {
    color: $text-color-secondary;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    letter-spacing: 0.0125rem;
  }
}

.converstaion-filter-checkbox {
  width: 1rem;
  height: 1rem;
  border-radius: 0.185rem;
  border: 1px solid $checkbox-border-color;
  appearance: none;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: $checkbox-color-3dd598;
    border: none;
  }

  &:checked::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    clip-path: polygon(28% 45%, 20% 55%, 46% 77%, 82% 35%, 73% 26%, 45% 60%);
    background-color: white;
    position: absolute;
  }
}

.conversation-ai-input {
  height: 2.625rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  border: 1px solid $border-color-main;
  background-color: $bg-secondary;
  font-size: 0.875rem;
  font-weight: 400;
  color: $text-color-main;
  font-family: Inter, sans-serif;

  &::placeholder {
    color: $text-header-description;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }

  &:focus {
    border-color: $border-color-input-active;
    outline: none;
  }
}
