.subscriptions-plan-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0.75rem;
  padding: 2rem 1.75rem;
  min-width: 20rem;
  max-width: 26rem;
  min-height: 64rem;
  border-radius: 3.125rem;
  background-color: #ffffff;
  box-shadow: 3px -5px 40px 0px #cdcdd41a;

  .subscriptions-plan-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    min-height: 3.25rem;

    .yearly-subscription-plan-button {
      position: relative;
      width: calc(50% - 0.25rem);

      .yearly-discount-message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        position: absolute;
        top: -1.5rem;
        left: 0;
        width: 100%;

        .yearly-discount-message-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.25rem;
        }
        .yearly-discount-message-text {
          font-size: 0.75rem;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          color: #000000;
          white-space: nowrap;
        }
      }
    }
  }

  .subscriptions-plan-name {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.25rem;
    text-align: center;
    color: #171725;
    padding: 1rem 0;
    letter-spacing: 0.00625rem;
  }
  .subscriptions-plan-description {
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-align: center;
    color: #696974;
    letter-spacing: 0.00625rem;
  }
  .subscriptions-plan-price-value {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 3.44rem;
    line-height: 4.125rem;
    text-align: center;
    color: #0a090b;
    letter-spacing: -3%;
    padding-top: 1.5rem;
    padding-bottom: 1rem;

    .subscriptions-plan-price {
      font-family: 'Inter', sans-serif;
      font-weight: 475;
      font-size: 1rem;
      line-height: 1.375rem;
      color: #4f4d55;
      letter-spacing: -0.01125rem;
      padding-left: 0.5rem;
    }
  }
  .subscriptions-plan-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.22rem;
    border: 1px solid #50b5ff;
    background-color: #50b5ff;
    border-radius: 0.625rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
    color: #fafafb;
    cursor: pointer;
  }
  .subscriptions-plan-agent-description {
    font-family: 'Roboto', sans-serif;
    line-height: 1.5rem;
    text-align: center;
    color: #696974;
    letter-spacing: 0.00625rem;
    padding-top: 1rem;
  }
  .subscriptions-plan-features {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 1rem;

    .subscriptions-plan-feature-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
      padding: 0.5rem 0;

      .subscriptions-plan-feature-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
      }
      .subscriptions-plan-feature-description {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        letter-spacing: 0.00625rem;
        color: #696974;
      }
    }
  }
}
