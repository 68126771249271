//auth backgrounds
.registration-background {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.registration-background-top {
  width: calc(100% - 8rem);
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 6rem;
}

.login-background {
  position: absolute;
  right: 4.6rem;
  height: 40%;
  width: auto;
  object-fit: cover;
}

.object-fit-cover {
  object-fit: cover;
}
