.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip-text {
  visibility: hidden;
  width: 200px; /* Adjust width as needed */
  background-color: #ffffff;
  color: #333;
  text-align: center;
  border-radius: 5px;
  padding: 10px 5px;
  position: absolute;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 79, 0.2);
}
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
