//z-index

.z0 {
  z-index: 0;
}

.z1 {
  z-index: 1;
}

.z2 {
  z-index: 2;
}

.z3 {
  z-index: 3;
}

.z4 {
  z-index: 4;
}

.z5 {
  z-index: 5;
}

.z-1 {
  z-index: -1;
}

.z-2 {
  z-index: -2;
}

//position
.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

//top
.top-0 {
  top: 0;
}

.top-0_15rem {
  top: 0.15rem;
}

.t-0_437rem {
  top: 0.437rem;
}

.top-0_5rem {
  top: 0.5rem;
}

.top-0_8rem {
  top: 0.8rem;
}

.top-1rem {
  top: 1rem;
}

.top-1_2rem {
  top: 1.2rem;
}

.top-1_4rem {
  top: 1.4rem;
}

.top-1_5rem {
  top: 1.5rem;
}

.top-1_8rem {
  top: 1.8rem;
}

.top-2_3rem {
  top: 2.3rem;
}

.top-2_375rem {
  top: 2.375rem;
}

.top-2_5rem {
  top: 2.5rem;
}

.top-2_65rem {
  top: 2.65rem;
}

.top-2_8rem {
  top: 2.8rem;
}

.top-3_5rem {
  top: 3.5rem;
}

.top-4_5rem {
  top: 4.5rem;
}

.top-_-1rem {
  top: -1rem;
}

.top-_-2_5rem {
  top: -2.5rem;
}

//bottom
.bottom-0 {
  bottom: 0;
}

.bottom-0_285rem {
  bottom: 0.285rem;
}

.bottom-1rem {
  bottom: 1rem;
}

.bottom-2rem {
  bottom: 2rem;
}

.bottom-2_25rem {
  bottom: 2.25rem;
}

.bottom-_-0_0625rem {
  bottom: -0.0625rem;
}

.bottom-_-3rem {
  bottom: -3rem;
}

.bottom-_-4rem {
  bottom: -4rem;
}

//left
.left-0 {
  left: 0;
}

.left-0_285rem {
  left: 0.285rem;
}

.left-0_562rem {
  left: 0.562rem;
}

.left-1rem {
  left: 1rem;
}

.left-4_125rem {
  left: 4.125rem;
}

.left-5rem {
  left: 5rem;
}

.left-_-0_5rem {
  left: -0.7rem;
}

.left-_-4rem {
  left: -4rem;
}

//right
.right-0 {
  right: 0;
}

.right-0_25rem {
  right: 0.25rem;
}

.right-0_625rem {
  right: 0.625rem;
}

.right-1rem {
  right: 1rem;
}

.right-3rem {
  right: 3rem;
}

.right-_-1rem {
  right: -1rem;
}

.right-_-2rem {
  right: -2rem;
}

.right-_-3rem {
  right: -3rem;
}

.right-_-4rem {
  right: -4rem;
}

//margin
.m-0_188rem {
  margin: 0.188rem;
}

.m-0_5rem-0 {
  margin: 0.5rem 0;
}

//margin-top

.mt-0_2rem {
  margin-top: 0.2rem;
}

.mt-0_25rem {
  margin-top: 0.25rem;
}
.mt-0_375rem {
  margin-top: 0.375rem;
}

.mt-0_5rem {
  margin-top: 0.5rem;
}

.mt-0_75rem {
  margin-top: 0.75rem;
}

.mt-1rem {
  margin-top: 1rem;
}

.mt-1_375rem {
  margin-top: 1.375rem;
}

.mt-1_5rem {
  margin-top: 1.5rem;
}

.mt-1_75rem {
  margin-top: 1.75rem;
}

.mt-1_875rem {
  margin-top: 1.875rem;
}

.mt-2rem {
  margin-top: 2rem;
}

.mt-2_3rem {
  margin-top: 2.3rem;
}

.mt-2_75rem {
  margin-top: 2.75rem;
}

.mt-3rem {
  margin-top: 3rem;
}

.mt-5_5rem {
  margin-top: 5.5rem;
}

.mt-6rem {
  margin-top: 6rem;
}

.mt-6_5rem {
  margin-top: 6.5rem;
}

.mt-8rem {
  margin-top: 8rem;
}

//margin-left
.ml-0_25rem {
  margin-left: 0.25rem;
}

.ml-0_5rem {
  margin-left: 0.5rem;
}

.ml-0_75rem {
  margin-left: 0.75rem;
}

.ml-1rem {
  margin-left: 1rem;
}

.ml-2_5rem {
  margin-left: 2.5rem;
}

//margin-right
.mr-0_375rem {
  margin-right: 0.375rem;
}
.mr-0_5rem {
  margin-right: 0.5rem;
}

.mr-0_75rem {
  margin-right: 0.75rem;
}

.mr-0_625rem {
  margin-right: 0.625rem;
}

.mr-1rem {
  margin-right: 1rem;
}

.mr-1_25rem {
  margin-right: 1.25rem;
}

.mr-1_5rem {
  margin-right: 1.5rem;
}

.mr-1_75rem {
  margin-right: 1.75rem;
}

.mr-3rem {
  margin-right: 3rem;
}

//margin-bottom
.mb-0_25rem {
  margin-bottom: 0.25rem;
}

.mb-0_375rem {
  margin-bottom: 0.375rem;
}

.mb-0_5rem {
  margin-bottom: 0.5rem;
}

.mb-0_75rem {
  margin-bottom: 0.75rem;
}

.mb-1_25rem {
  margin-bottom: 1.25rem;
}

.mb-1_375rem {
  margin-bottom: 1.375rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.mb-1_5rem {
  margin-bottom: 1.5rem;
}

.mb-1_625rem {
  margin-bottom: 1.75rem;
}

.mb-1_75rem {
  margin-bottom: 1.75rem;
}

.mb-1_875rem {
  margin-bottom: 1.875rem;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.mb-2_375rem {
  margin-bottom: 2.375rem;
}

.mb-2_5rem {
  margin-bottom: 2.5rem;
}

.mb-3rem {
  margin-bottom: 3rem;
}

.mb-3_25rem {
  margin-bottom: 3.25rem;
}

.mb-3_5rem {
  margin-bottom: 3.5rem;
}

//padding
.p-0_188rem {
  padding: 0.188rem;
}

.p-0_375rem {
  padding: 0.375rem;
}

.p-0_5rem {
  padding: 0.5rem;
}

.p-0_625rem {
  padding: 0.625rem;
}

.p-0_75rem {
  padding: 0.75rem;
}

.p-1rem {
  padding: 1rem;
}

.p-1_25rem {
  padding: 1.25rem;
}

.p-1_5rem {
  padding: 1.5rem;
}

.p-1_75rem {
  padding: 1.75rem;
}

.p-1_875rem {
  padding: 1.875rem;
}

.p-2rem {
  padding: 2rem;
}

.p-2_625rem {
  padding: 2.625rem;
}

.p-2_625rem-0 {
  padding: 2.625rem 0;
}

.p-0-2rem {
  padding: 0 2rem;
}

.p-0_2rem-0 {
  padding: 0.2rem 0;
}

.p-0_5rem-0 {
  padding: 0.5rem 0;
}

.p-0_938rem-0 {
  padding: 0.938rem 0;
}

.p-0-0_0625rem {
  padding: 0 0.0625rem;
}

.p-0-0_625rem {
  padding: 0 0.625rem;
}

.p-0-0_75rem {
  padding: 0 0.75rem;
}

.p-0-1rem {
  padding: 0 1rem;
}

.p-0-1_875rem {
  padding: 0 1.875rem;
}

.p-0_188rem-1rem {
  padding: 0.188rem 1rem;
}

.p-0_25rem-0 {
  padding: 0.25rem 0;
}

.p-0_25rem-1rem {
  padding: 0.25rem 1rem;
}

.p-0_375rem-0 {
  padding: 0.375rem 0;
}

.p-0_375rem-0_75rem {
  padding: 0.375rem 0.75rem;
}

.p-0_5rem-1rem {
  padding: 0.5rem 1rem;
}

.p-0_5rem-0_375rem {
  padding: 0.5rem 0.375rem;
}

.p-0_125rem-0_625rem {
  padding: 0.125rem 0.625rem;
}

.p-1rem-1_25rem {
  padding: 1rem 1.25rem;
}

.p-1_25rem-1rem {
  padding: 1.25rem 1rem;
}

.p-1_312rem-0 {
  padding: 1.312rem 0;
}

.p-0-1_5rem {
  padding: 0 1.5rem;
}

.p-0_625rem-0 {
  padding: 0.625rem 0;
}

.p-0_75rem-0 {
  padding: 0.75rem 0;
}

.p-1rem-0 {
  padding: 1rem 0;
}

.p-1rem-0_75rem {
  padding: 1rem 0.75rem;
}

.p-1rem-1_5rem {
  padding: 1rem 1.5rem;
}

.p-1_125rem-1_375rem {
  padding: 1.125rem 1.375rem;
}

.p-1_125rem-1_625rem {
  padding: 1.125rem 1.625rem;
}

.p-1_25rem-1_75rem {
  padding: 1.25rem 1.75rem;
}

.p-1_625rem-1_125rem {
  padding: 1.625rem 1.125rem;
}

.p-1_875rem-0 {
  padding: 1.875rem 0;
}

.p-2rem-0 {
  padding: 2rem 0;
}

.p-2rem-2_5rem {
  padding: 2rem 2.5rem;
}

.p-2_375rem-0 {
  padding: 2.375rem 0;
}

.p-2_5rem-3_75rem {
  padding: 2.5rem 3.75rem;
}

.p-2_5rem-4_375rem {
  padding: 2.5rem 4.375rem;
}

.p-3_125rem-4_125rem {
  padding: 3.125rem 4.125rem;
}

.p-10rem-4rem {
  padding: 10rem 4rem;
}

//padding-top
.pt-0_188rem {
  padding-top: 0.188rem;
}

.pt-0_2rem {
  padding-top: 0.2rem;
}

.pt-0_5rem {
  padding-top: 0.5rem;
}

.pt-0_75rem {
  padding-top: 0.75rem;
}

.pt-0_875rem {
  padding-top: 0.875rem;
}

.pt-1rem {
  padding-top: 1rem;
}

.pt-1_5rem {
  padding-top: 1.5rem;
}

.pt-1_75rem {
  padding-top: 1.75rem;
}

.pt-2rem {
  padding-top: 2rem;
}

.pt-2_125rem {
  padding-top: 1.875rem;
}

.pt-2_25rem {
  padding-top: 2.25rem;
}

.pt-2_5rem {
  padding-top: 2.5rem;
}

.pt-2_688rem {
  padding-top: 2.688rem;
}

.pt-3_25rem {
  padding-top: 3.25rem;
}

.pt-3_5rem {
  padding-top: 3.5rem;
}

.pt-4_25rem {
  padding-top: 4.25rem;
}

.pt-4_375rem {
  padding-top: 4.375rem;
}

.pt-7_375rem {
  padding-top: 7.375rem;
}

.pt-8rem {
  padding-top: 8rem;
}

.pt-16rem {
  padding-top: 16rem;
}

.pt-905rem {
  padding-top: 905rem;
}

//padding-bottom
.pb-0_375rem {
  padding-bottom: 0.375rem;
}

.pb-0_5rem {
  padding-bottom: 0.5rem;
}

.pb-0_625rem {
  padding-bottom: 0.625rem;
}

.pb-0_75rem {
  padding-bottom: 0.75rem;
}

.pb-0_938rem {
  padding-bottom: 0.938rem;
}

.pb-1rem {
  padding-bottom: 1rem;
}

.pb-1_375rem {
  padding-bottom: 1.375rem;
}

.pb-1_5rem {
  padding-bottom: 1.5rem;
}

.pb-1_75rem {
  padding-bottom: 1.75rem;
}

.pb-1_875rem {
  padding-bottom: 1.875rem;
}

.pb-2rem {
  padding-bottom: 2rem;
}

.pb-2_5rem {
  padding-bottom: 2.5rem;
}

.pb-2_625rem {
  padding-bottom: 2.625rem;
}

.pb-2_875rem {
  padding-bottom: 2.875rem;
}

.pb-4rem {
  padding-bottom: 4rem;
}

.pb-5rem {
  padding-bottom: 5rem;
}

.pb-7_8rem {
  padding-bottom: 7.8rem;
}

.pb-8_5rem {
  padding-bottom: 8.5rem;
}

.pb-10rem {
  padding-bottom: 10rem;
}

//padding-left
.pl-0 {
  padding-left: 0;
}

.pl-0_25rem {
  padding-left: 0.25rem;
}

.pl-0_375rem {
  padding-left: 0.625rem;
}

.pl-0_5rem {
  padding-left: 0.5rem;
}

.pl-0_625rem {
  padding-left: 0.625rem;
}

.pl-0_75rem {
  padding-left: 0.75rem;
}

.pl-1rem {
  padding-left: 1rem;
}

.pl-1_125rem {
  padding-left: 1.125rem;
}

.pl-1_25rem {
  padding-left: 1.25rem;
}

.pl-1_5rem {
  padding-left: 1.5rem;
}

.pl-1_75rem {
  padding-left: 1.75rem;
}

.pl-2rem {
  padding-left: 2rem;
}

.pl-2_375rem {
  padding-left: 2.375rem;
}

.pl-2_5rem {
  padding-left: 2.5rem;
}

.pl-2_625rem {
  padding-left: 2.625rem;
}

.pl-3rem {
  padding-left: 3rem;
}

.pl-3_5rem {
  padding-left: 3.5rem;
}

.pl-4rem {
  padding-left: 4rem;
}

.pl-4_5rem {
  padding-left: 4.5rem;
}

.pl-10rem {
  padding-left: 10rem;
}

.pl-5_625rem {
  padding-left: 5.625rem;
}

//padding-right
.pr-0_25rem {
  padding-right: 0.25rem;
}

.pr-0_5rem {
  padding-right: 0.5rem;
}

.pr-0_625rem {
  padding-right: 0.625rem;
}

.pr-0_875rem {
  padding-right: 0.875rem;
}

.pr-0_625rem {
  padding-right: 0.625rem;
}

.pr-1rem {
  padding-right: 1rem;
}

.pr-1_25rem {
  padding-right: 1.25rem;
}

.pr-1_375rem {
  padding-right: 1.375rem;
}

.pr-1_5rem {
  padding-right: 1.5rem;
}

.pr-1_75rem {
  padding-right: 1.75rem;
}

.pr-2rem {
  padding-right: 2rem;
}

.pr-2_25rem {
  padding-right: 2.25rem;
}

.pr-5_5rem {
  padding-right: 5.5rem;
}
