@import '../constants/colors';

.icon-hover {
  &:hover {
    cursor: pointer;
    fill: $active-sidebar-item-color;
  }
}

.logout-icon {
  width: 2rem;
  height: 2rem;
}

.pagination-icon {
  &:hover {
    border: 1px solid $active-sidebar-item-color;
    cursor: pointer;
    path {
      fill: $active-sidebar-item-color;
    }
    span {
      font-weight: 700;
      color: $active-sidebar-item-color;
    }
  }

  path {
    &:active {
      fill: $active-sidebar-item-color;
    }
  }
  span {
    &:active {
      font-weight: 700;
      color: $active-sidebar-item-color;
    }
  }
}

.t-rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.4s;
}

.icon-hovered {
  &:hover {
    cursor: pointer;
    path {
      fill: $active-sidebar-item-color;
    }
  }
}

.icon-active {
  path {
    fill: $active-sidebar-item-color;
  }
}

.icon-white {
  path {
    fill: $bg-white;
  }
}
