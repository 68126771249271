@import '../constants/colors';

.primary-link {
  color: $link-primary-color;
  text-decoration: none;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.006rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.secondary-link {
  color: $link-secondary-color;
  text-decoration: none;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.006rem;
  padding: 0 0.2rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.secondary-link-roboto {
  color: $link-secondary-color;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.006rem;
  padding: 0 0.2rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.secondary-sized-link {
  color: $link-secondary-color;
  text-decoration: none;
  letter-spacing: 0.006rem;
  font-weight: 600;
  padding: 0 0.2rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.td-none {
  text-decoration: none;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}
